import React from 'react';
import { Form, FormControl, Button, Row, Col } from 'react-bootstrap';
import '../assets/styles/portfolio.css';
import { FaSearch } from "react-icons/fa";

const portfolioData = [
  { id: 1, creatorName: "John Doe", squadName: "Design Squad", imageUrl: require('../assets/images/imgs-for-portfolio/1.png') },
  { id: 2, creatorName: "EchoMark", squadName: "EchoMark", imageUrl: require('../assets/images/imgs-for-portfolio/2.png') },
  { id: 19, creatorName: "PulseMedia", squadName: "PulseMedia", aspect_ratio: "2560x1440", imageUrl: require('../assets/images/imgs-for-landingpage/19.png') },
  { id: 3, creatorName: "Alice Johnson", squadName: "Art Aficionados", imageUrl: require('../assets/images/imgs-for-portfolio/3.png') },
  { id: 4, creatorName: "Robert Brown", squadName: "Digital Dreamers", imageUrl: require('../assets/images/imgs-for-portfolio/4.png') },
  { id: 20, creatorName: "BrandNest", squadName: "BrandNest", aspect_ratio: "1080x1350", imageUrl: require('../assets/images/imgs-for-landingpage/20.png') },
  { id: 5, creatorName: "Michael White", squadName: "Creative Minds", imageUrl: require('../assets/images/imgs-for-portfolio/5.png') },
  { id: 6, creatorName: "Emily Green", squadName: "Design Squad", imageUrl: require('../assets/images/imgs-for-portfolio/6.png') },
];


const Portfolio: React.FC = () => {
  return (
    <div className="portfolio-section">
      <div className='portfolio-background'>
      <h2 className='portfolio-centre-heading'>Need someone to deliver exclusively for you?? Search for portfolios</h2>
      <Form className="d-flex justify-content-center">
        <FormControl
          type="search"
          placeholder="Search"
          className="me-2 landing-search-bar"
          aria-label="Search"
        />
        <Button variant="outline-success" className="landing-search-button"><FaSearch/></Button>
      </Form>
      </div>
      <div className="portfolio-scrollable-container">
          <Row className="portfolio-row">
            {portfolioData
              .map((portfolio) => (
                <div key={portfolio.id} className="portfolio-box">
                  <div className="portfolio-image-content" style={{backgroundImage:`url(${portfolio.imageUrl})`}}></div>
                  <div className="portfolio-footer">{portfolio.creatorName}</div>
                  <div className="portfolio-sub-footer">
                    {portfolio.squadName}
                  </div>
                </div>
              ))}
          </Row>
      </div>
    </div>
  );
};

export default Portfolio;

import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from './screens/LandingPage.tsx';
import PolicyPage from './screens/PolicyPage.tsx';
import TermsPage from './screens/TermsPage.tsx';

const Layout = () => {
  const location = useLocation();

  return (
    <div className="App">
      <main className="">
        <div className='app-container'>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/genbook/privacy-policy" element={<PolicyPage/>} />
            <Route path="/genbook/termsofuse" element={<TermsPage/>} />
          </Routes>
        </div>  
      </main>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;

import React from 'react';
import { Row } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import '../assets/styles/premium-creatives.css';
import { MdWorkspacePremium } from "react-icons/md";

const premiumCreativesData = [
  {
    id: 1,
    creatorName: "John Doe",
    projectName: "Project Alpha",
    squadName: "Creative Squad",
    profileImage: require('../assets/images/imgs-premium-creatives/1.webp'),
  },
  {
    id: 2,
    creatorName: "Jane Smith",
    projectName: "Project Beta",
    squadName: "Design Squad",
    profileImage: require('../assets/images/imgs-premium-creatives/2.webp'),
  },
  {
    id: 3,
    creatorName: "Alice Johnson",
    projectName: "Project Gamma",
    squadName: "Image Craft",
    profileImage: require('../assets/images/imgs-premium-creatives/3.webp'),
  },
  {
    id: 4,
    creatorName: "Michael Brown",
    projectName: "Project Delta",
    squadName: "Poster Squad",
    profileImage: require('../assets/images/imgs-premium-creatives/4.webp'),
  },
  {
    id: 5,
    creatorName: "Emily Davis",
    projectName: "Project Epsilon",
    squadName: "Newsletter Crew",
    profileImage: require('../assets/images/imgs-premium-creatives/5.png'),
  },
];

const PremiumCreatives: React.FC = () => {
  return (
    <div className="premium-creatives-section">
      <h2>A Glimpse of Premium Creatives</h2>
      <div className="landing-scrollable-container">
        <Row className="creative-row">
          {premiumCreativesData.map((item) => (
            <div key={item.id} className="creative-box">
              <MdWorkspacePremium className='premium-icon'/>
              <div className="creative-content" style={{backgroundImage:`url(${item.profileImage})`}}></div>
              <div className="creative-profile">
                <div className="creative-details">
                  <div className="creative-footer">{item.creatorName}</div>
                  <div className="creative-sub-footer">{item.squadName}</div>
                </div>
              </div>
              <div className="project-name">{item.projectName}</div>
            </div>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default PremiumCreatives;

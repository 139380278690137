import React, { useState } from 'react';
import { Form, FormControl, Button, Row, Container } from 'react-bootstrap';
import '../assets/styles/assets.css';
import { FaSearch } from "react-icons/fa";

const dummyData = [
  { id: 3, name: "Beautified Workspace", studio: "Image Craft", aspect_ratio: "600x800", img_url: require('../assets/images/imgs-for-landingpage/3.png') },
  { id: 7, name: "Art Workshop", studio: "Image Craft", aspect_ratio: "1920x1200", img_url: require('../assets/images/imgs-for-landingpage/7.png') },
  { id: 8, name: "Corporate Deal", studio: "Poster", aspect_ratio: "800x1200", img_url: require('../assets/images/imgs-for-landingpage/8.png') },
  { id: 9, name: "Family Meet", studio: "Image Craft", aspect_ratio: "850x1100", img_url: require('../assets/images/imgs-for-landingpage/9.png') },
  { id: 10, name: "Creativing Head", studio: "Image Craft", aspect_ratio: "1366x768", img_url: require('../assets/images/imgs-for-landingpage/10.png') },
  { id: 11, name: "Abstract Girl", studio: "Poster", aspect_ratio: "1000x1500", img_url: require('../assets/images/imgs-for-landingpage/11.png') },
  { id: 12, name: "Weather Forecast", studio: "Poster", aspect_ratio: "1024x1024", img_url: require('../assets/images/imgs-for-landingpage/12.png') },
  { id: 13, name: "Cyber World", studio: "Image Craft", aspect_ratio: "3840x2160", img_url: require('../assets/images/imgs-for-landingpage/13.png') },
  { id: 14, name: "Business Deal", studio: "Poster", aspect_ratio: "900x1300", img_url: require('../assets/images/imgs-for-landingpage/14.png') },
  { id: 15, name: "Ecommerce", studio: "Newsletter", aspect_ratio: "600x900", img_url: require('../assets/images/imgs-for-landingpage/15.png') },
  { id: 16, name: "Startup Pitch", studio: "Image Craft", aspect_ratio: "2048x1536", img_url: require('../assets/images/imgs-for-landingpage/16.png') },
  { id: 17, name: "Business Presentations", studio: "Poster", aspect_ratio: "720x1280", img_url: require('../assets/images/imgs-for-landingpage/17.png') },
];

const Assets: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(e.target.value);
  };

  return (
    <div className="assets-section">
      <div className='assets-background'>
        <h2 className='landing-centre-heading'>
          Search anything - Images, Posters, Newsletters, Templates...
        </h2>
        <Form className="d-flex justify-content-center landing-search-form">
          {/* Dropdown for selecting category */}
          <Form.Select
            className="me-2 landing-dropdown"
            value={selectedCategory}
            onChange={handleCategoryChange}
            aria-label="Filter by category"
          >
            <option value="all">All</option>
            <option value="newsletter">Newsletter</option>
            <option value="blogs">Blogs</option>
            <option value="poster">Poster</option>
          </Form.Select>
          {/* Search bar */}
          <FormControl
            type="search"
            placeholder="Search"
            className="me-2 landing-search-bar"
            aria-label="Search"
          />
          <Button variant="outline-success" className="landing-search-button">
            <FaSearch />
          </Button>
        </Form>
      </div>
      <div className="asset-scrollable-container">
        <Row className="image-row">
          {dummyData
            .filter(item => selectedCategory === "all" || item.studio.toLowerCase() === selectedCategory)
            .map((item) => (
              <div key={item.id} className="image-box">
                <div className="image-content" style={{backgroundImage:`url(${item.img_url})`}}></div>
                <div className="asset-footer">{item.name}</div>
                <div className="asset-sub-footer">
                  {item.studio} | {item.aspect_ratio}
                </div>
              </div>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Assets;

import React,{ useState,useEffect} from 'react';
import { Row, Col } from 'react-bootstrap';
import '../assets/styles/policy.css';
import Footer from '../components/Footer.tsx';
import { Navbar, Nav, Container} from 'react-bootstrap';
import logo_img from '../assets/images/GenbookStudio_logo.png';
import { TbMenuDeep, TbSearch, TbArrowDownCircle } from 'react-icons/tb';
import { CiSquareChevRight } from "react-icons/ci";
import '../assets/styles/landing-page.css';


const PolicyPage = () => {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    document.title = "Genbook Studio - Privacy Policy";

    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Read Genbook Studio's Privacy Policy to understand how we protect your data and your rights as a user.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "Genbook, Genbook Studio, privacy policy, data protection, user rights, digital assets";
    document.head.appendChild(metaKeywords);

    const metaAuthor = document.createElement('meta');
    metaAuthor.name = "author";
    metaAuthor.content = "Genbook Studio Team";
    document.head.appendChild(metaAuthor);

    const linkCanonical = document.createElement('link');
    linkCanonical.rel = "canonical";
    linkCanonical.href = "https://genbookstudio.com/privacy-policy";
    document.head.appendChild(linkCanonical);

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
      document.head.removeChild(metaAuthor);
      document.head.removeChild(linkCanonical);
    };
  }, []);
  return (
    <Container className="policy-page mt-5 mb-5">
      <Navbar expand="lg" className="py-2 landing-header-container">
        <Container fluid className="d-flex justify-content-between align-items-center">
          <Navbar.Brand href="/" className="d-flex align-items-center">
            <img src={logo_img} alt='logo' className='header-logo'/>
            <span className="landing-navbar-title">Genbook Studio</span>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
            className='header-toggle'
          >
            <TbMenuDeep className="landing-header-icon" />
          </Navbar.Toggle>
          <Nav className="ml-auto d-flex align-items-center">
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="header-text-links">
                <Nav.Link href="#community" className="header-text-link" onClick={() => setExpanded(false)}>Community</Nav.Link>
                <Nav.Link href="#pricing" className="header-text-link" onClick={() => setExpanded(false)}>Pricing</Nav.Link>
                <Nav.Link href="#about-us" className="header-text-link" onClick={() => setExpanded(false)}>About Us</Nav.Link>
                <Nav.Link href="/" className="header-text-link">Early Access</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Nav>
        </Container>
      </Navbar>
      <Row>
        <Col>
          <h1 className="policy-title">Privacy Policy</h1>
          <h5 className='policy-eff-date'>Effective Date: November 5, 2024</h5>
          
          <section className="policy-section">
            <p>Welcome to Genbook, a digital creative platform by Dimension Forge ("we," "us," or "our"). This Privacy and Cookies Policy outlines how we collect, use, disclose, and protect information to deliver a personalised, secure, and innovative experience to our users. By
using our Service, you agree to the terms of this policy and acknowledge the importance of
transparency and security in protecting your information.</p>
            <p>If you have any concerns or wish to limit certain data uses, please contact us or consult your account settings for options to control data collection and sharing. For any terms not defined in this document, please refer to our Terms and Conditions.</p>
            <p><strong>If you do not agree to any part of this policy, please discontinue use of our Service.</strong></p>
          </section>

          <hr className="policy-divider" />

          <h3 className='policy-subhead'>1. INFORMATION WE COLLECT</h3>
          <section className="policy-section">
            <p>We collect information to personalise your experience, optimise our services, and improve
recommendations through machine learning and other analytical methods. Below, we break
down the types of data we collect, why it’s important for your experience, and how it is
handled:</p>
            <h5 className='policy-section-head'>(a) Information You Provide Directly</h5>
            <p>When you interact with Genbook, you may be asked to provide information such as:</p>
            <ul>
              <li><strong>Account Registration:</strong> Name, email address, password, and contact details, necessary to create and secure your account.</li>
              <li><strong>User-Generated Content:</strong> Content you upload or create within Genbook (e.g.,
images, designs, videos). This information enables us to deliver personalised
features and suggest relevant templates and tools based on your creative activities.</li>
              <li><strong>Communication Data:</strong> Any messages or feedback sent through Genbook’s support
channels. We collect and analyse this data to address concerns, improve customer
support, and ensure continuous improvements to our Service</li>
            </ul>

            <p>Please note that without providing certain essential information, you may experience limited
            or no access to some features or a less personalised experience on Genbook.</p>
            
            <h5 className='policy-section-head'>(b) Information from Third-Party Applications</h5>
            <p>If you choose to connect Genbook with third-party services (such as Google, Facebook, or
              other social media accounts), we may receive relevant data, such as:</p>
            <ul>
              <li><strong>Authentication Information:</strong>  Data like user IDs, profile photos, or contacts if you
              grant permissions, to facilitate login, share designs, or connect with other users</li>
              <li><strong>Additional Permissions:</strong> Certain features may request permission to access
third-party data (e.g., social media posts) to enhance sharing options and allow
easier workflow integrations.</li>
            </ul>

            <p>These integrations aim to improve functionality and personalization but are entirely optional.
You can manage or disconnect these integrations through the settings on the respective
third-party platforms.</p>

            <h5 className='policy-section-head'>(c) Information Collected Automatically</h5>
            <p>We collect specific data automatically when you use Genbook to help us understand user
            behaviour and improve the Service:</p>
            <ul>
              <li><strong>Usage Data:</strong> Click patterns, navigation flows, and time spent on various features.
              This helps us optimise Genbook’s usability and introduce relevant improvements</li>
              <li><strong>Log Files:</strong> Information such as IP addresses, browser types, device data, and
              session times to assist with debugging, troubleshoot issues, and ensure stability.</li>
            </ul>

            <h5 className='policy-section-head'>(d) Camera and File Access</h5>
            <p>Genbook may require access to your device’s camera and files for key features, such as
uploading images and videos for design projects. This access is strictly used to enable the
functionality of Genbook, and we only collect the necessary data needed to support these
functions:</p>
            <ul>
              <li><strong>Camera Access:</strong> Allows users to take and upload photos directly within Genbook to
              enhance their creative projects.</li>
              <li><strong>File Access:</strong> Enables uploading of media files (e.g., photos, videos, and documents)
              to be used within Genbook’s design tools.</li>
            </ul>
            <p>We do not store or share these files with any third parties unless required to deliver the
Service’s intended functionality. Camera and file permissions can be managed through your
device’s settings if you wish to modify access permissions at any time.</p>

            <h5 className='policy-section-head'>(e) Cookies and Tracking Technologies</h5>
            <p>We use cookies and similar tracking technologies to:</p>
            <ul>
              <li>Facilitate smoother navigation and personalise user experience.</li>
              <li>Store user preferences, such as language settings or recent design templates.</li>
              <li>Collect aggregate metrics to analyse user trends and optimise Service features.</li>
            </ul>
            <p>Most browsers allow you to control or disable cookies. However, please be aware that
            blocking cookies may impact certain features on Genbook.</p>

            <h5 className='policy-section-head'>(f) Device Identifiers and Location Data</h5>
            <p>To further enhance personalization, we may collect:</p>
            <ul>
              <li><strong>Device Identifiers:</strong> Android Advertising ID, iOS Identifier for Advertisers, and similar
              identifiers help us understand device compatibility and improve user experience.</li>
              <li><strong>Location Data:</strong> Approximate location data, when allowed, may be collected to offer
localised content or language preferences. We do not store detailed location
histories.</li>
            </ul>
          </section>

          <h3 className='policy-subhead'>2. HOW WE USE YOUR INFORMATION</h3>
          <section className="policy-section">
            <p>The data we collect supports various key functions essential to providing an optimal experience on Genbook:</p>
            <ul>
                <li><strong className='policy-section-strong'>Personalization:</strong> Recommending relevant content, tools, and templates tailored to your creative needs.</li>
                <li><strong className='policy-section-strong'>Service Improvement:</strong> Using anonymous, aggregated data to refine our features, introduce new functionality, and provide a more responsive experience.</li>
                <li><strong className='policy-section-strong'>Communication:</strong> Sending necessary updates, responding to inquiries, and addressing any reported issues.</li>
                <li><strong className='policy-section-strong'>Security and Fraud Prevention:</strong> Proactively monitoring for suspicious activities and enhancing the security protocols for all Genbook users.</li>
            </ul>
            <p>By analyzing usage patterns, we strive to identify and resolve common user pain points,
continuously working toward a seamless and efficient creative platform. Additionally, we are
committed to only using your data in ways that benefit and improve your experience with
Genbook.</p>
        </section>

          <h3 className='policy-subhead'>3. SHARING YOUR INFORMATION</h3>
          <section className="policy-section">
            <p>Dimension Forge places a high priority on user privacy. We do not share user data with
external third parties except as required by law or strictly necessary for Service operations.
Situations where data may be shared include:</p>
            <ul>
              <li><strong className='policy-section-strong'>Service Providers:</strong> We work with reputable third-party providers (e.g., cloud storage,
analytics providers) under strict confidentiality to enable essential Service functions.
These providers are only given the minimum data necessary for their tasks.</li>
              <li><strong className='policy-section-strong'>Legal Requirements:</strong> In cases of legal obligations or law enforcement requests, we
              may be required to share limited data to comply with applicable laws.</li>
            </ul>
            <p>We may also partner with third-party advertising services to display ads within Genbook.
While we aim to ensure ad relevance and quality, please note that we are not responsible for
the content, links, or privacy practices of these ads or any external websites they may lead
to. Any content accessed via third-party ads falls under the privacy practices of those
external sites or providers. We recommend reviewing their policies before interacting with
external content.</p>
          </section>

          <h3 className='policy-subhead'>4. DATA PROTECTION AND RETENTION</h3>
          <section className="policy-section">
            <p>We understand the importance of data security and employ industry-standard practices to
            protect your data. Safeguards we use include:</p>
            <ul>
              <li ><strong className='policy-section-strong'>Data Encryption:</strong> Sensitive data, such as account information, is encrypted during
              storage and transmission to prevent unauthorised access.</li>
              <li><strong className='policy-section-strong'>Regular Security Audits:</strong> Our security measures are periodically reviewed to
              address new vulnerabilities and ensure robust protection.</li>
            </ul>
            <p>Despite our efforts, no method of electronic storage is entirely secure, and we cannot
guarantee absolute data security. We encourage users to take their own security
precautions, such as using strong, unique passwords.</p>
            <p>We retain your information as long as necessary to fulfil the purposes outlined in this policy
and for any additional period required for legal, security, or audit purposes. After this, we
securely delete or anonymize data, in line with industry practices.</p>
          </section>

          <h3 className='policy-subhead'>5. COOKIES POLICY</h3>
          <section className="policy-section">
            <p>Cookies are small data files used to store certain preferences and usage information.
            Genbook uses cookies to:</p>
            <ul>
                <li>Enhance your navigation and user experience, allowing seamless access to features.</li>
                <li>Track anonymized analytics data to understand user trends, optimise our interface,
                and improve Service functionality.</li>
                <li>Ensure that user preferences persist across sessions for a smoother user
                experience.</li>
            </ul>
            <p>Most web browsers automatically accept cookies, but you may choose to disable cookies in
your browser settings. Note that disabling cookies may limit some functionalities on
Genbook.</p>
          </section>


          <h3 className='policy-subhead'>6. THIRD-PARTY LINKS AND SERVICES</h3>
          <section className="policy-section">
            <p>Our Service may contain links to third-party websites or services that operate independently
            from Dimension Forge. Please note:</p>
            <ul>
                <li>We have no control over the privacy practices, content, or policies of third-party
                websites.</li>
                <li>Dimension Forge does not assume responsibility for third-party privacy policies, and
                we encourage you to review these policies before sharing any personal information.</li>
            </ul>
          </section>

          <h3 className='policy-subhead'>7. CHANGES TO THIS POLICY</h3>
          <section className="policy-section">
            <p>Dimension Forge reserves the right to update this Privacy and Cookies Policy as needed to
reflect changes in our practices, regulatory obligations, or operational requirements. We will
notify users of significant updates by posting the revised policy on this page and may provide
additional notices via email or app notifications, where appropriate.</p>
          </section>

          <h3 className='policy-subhead'>8. CONTACT US</h3>
          <section className="policy-section">
            <p>If you have any questions or concerns about this Privacy and Cookies Policy, or if you wish
            to request data adjustments, deletions, or restrictions, please reach out to us at:</p>
            <ul>
              <li><strong className='policy-section-strong'>Email:</strong> contactus@dimensionforge.ai</li>
              <li><strong className='policy-section-strong'>Mailing Address:</strong> 3rd Floor, 86-90 Paul St, London EC2A 4NE</li>
            </ul>
          </section>
        </Col>
      </Row>
      <Footer/>
    </Container>
  );
};

export default PolicyPage;

import React,{ useState,useEffect} from 'react';
import { Row, Col } from 'react-bootstrap';
import '../assets/styles/policy.css';
import Footer from '../components/Footer.tsx';
import { Navbar, Nav, Container} from 'react-bootstrap';
import logo_img from '../assets/images/GenbookStudio_logo.png';
import { TbMenuDeep, TbSearch, TbArrowDownCircle } from 'react-icons/tb';
import { CiSquareChevRight } from "react-icons/ci";
import '../assets/styles/landing-page.css';

const TermsPage = () => {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    document.title = "Genbook Studio - Terms of Use";

    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Explore Genbook Studio's Terms of Use to understand the rules and guidelines for using our platform.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "Genbook, Genbook Studio, terms of use, user agreement, digital assets, platform rules";
    document.head.appendChild(metaKeywords);

    const metaAuthor = document.createElement('meta');
    metaAuthor.name = "author";
    metaAuthor.content = "Genbook Studio Team";
    document.head.appendChild(metaAuthor);

    const linkCanonical = document.createElement('link');
    linkCanonical.rel = "canonical";
    linkCanonical.href = "https://genbookstudio.com/terms-of-use";
    document.head.appendChild(linkCanonical);

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
      document.head.removeChild(metaAuthor);
      document.head.removeChild(linkCanonical);
    };
  }, []);
  return (
    <Container className="policy-page mt-5 mb-5">
      <Navbar expand="lg" className="py-2 landing-header-container">
        <Container fluid className="d-flex justify-content-between align-items-center">
          <Navbar.Brand href="/" className="d-flex align-items-center">
            <img src={logo_img} alt='logo' className='header-logo'/>
            <span className="landing-navbar-title">Genbook Studio</span>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
            className='header-toggle'
          >
            <TbMenuDeep className="landing-header-icon" />
          </Navbar.Toggle>
          <Nav className="ml-auto d-flex align-items-center">
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="header-text-links">
                <Nav.Link href="#community" className="header-text-link" onClick={() => setExpanded(false)}>Community</Nav.Link>
                <Nav.Link href="#pricing" className="header-text-link" onClick={() => setExpanded(false)}>Pricing</Nav.Link>
                <Nav.Link href="#about-us" className="header-text-link" onClick={() => setExpanded(false)}>About Us</Nav.Link>
                <Nav.Link href="/" className="header-text-link">Early Access</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Nav>
        </Container>
      </Navbar>
      <Row>
        <Col>
          <h1 className="policy-title">Terms of Use</h1>
          <h5 className='policy-eff-date'>Effective Date: November 5, 2024</h5>
          
          <section className="policy-section">
            <p>Welcome to Genbook, a cutting-edge AI-driven platform by Dimension Forge designed for AI image generation, creative networking, and community building. By accessing or using Genbook, you agree to comply with these terms. Please read them carefully. If you do not agree with these terms, do not use Genbook.</p>
          </section>

          <hr className="policy-divider" />

          <h3 className='policy-subhead'>1. ACCEPTANCE OF TERMS</h3>
          <section className="policy-section">
            <p>By creating an account, clicking “I Agree,” or otherwise using Genbook, you agree to all provisions of these Terms of Use and our policies, including the Privacy Policy. If you do not agree, refrain from accessing Genbook.</p>
          </section>

          <h3 className='policy-subhead'>2. DEFINITIONS</h3>
          <section className="policy-section">
            <ul>
              <li><strong className='policy-section-strong'>Platform or Genbook:</strong> The web and mobile applications provided by Dimension Forge for AI image generation, linking, and community building.</li>
              <li><strong className='policy-section-strong'>Company:</strong> Refers to Dimension Forge Ltd.</li>
              <li><strong className='policy-section-strong'>Content:</strong> Any text, images, links, or data shared or generated by you on Genbook.</li>
              <li><strong className='policy-section-strong'>User or You:</strong> Any individual or entity accessing or using Genbook.</li>
              <li><strong className='policy-section-strong'>Third-Party Services:</strong> Content, software, or services provided by external providers, including advertisers, integrated with or linked through Genbook.</li>
            </ul>
          </section>

          <h3 className='policy-subhead'>3. LICENCE TO USE GENBOOK</h3>
          <section className="policy-section">
            <p>Dimension Forge grants you a limited, revocable, non-exclusive, non-transferable licence to use Genbook solely for personal and non-commercial purposes, in line with these Terms of Use. This licence includes permissions to:</p>
            <ul>
              <li>Generate AI-created images and artwork.</li>
              <li>Connect with and follow other users.</li>
              <li>Share, network, and collaborate with the Genbook community. You agree not to use
the Platform for any other purposes without explicit authorization from Dimension
Forge.</li>
            </ul>
          </section>

          <h3 className='policy-subhead'>4. ACCEPTABLE USE POLICY</h3>
          <section className="policy-section">
            <p>Genbook fosters a safe, inclusive community for creativity and collaboration. You agree not to:</p>
            <ul>
              <li>Create or share content that is illegal, harmful, offensive, discriminatory, defamatory, or otherwise objectionable.</li>
              <li>Use the platform to spread misinformation, harass, bully, or threaten others.</li>
              <li>Disrupt, hack, or attempt unauthorised access to Genbook, other users’ accounts, or any connected networks.</li>
              <li>Reverse-engineer, copy, distribute, or exploit any proprietary code, technology, or
              content owned by Dimension Forge or its affiliates.</li>
              <li>Engage in any activity that could interfere with Genbook’s functionality or security.</li>
            </ul>
          </section>

          <h3 className='policy-subhead'>5. AI CONTENT GENERATION POLICY</h3>
          <section className="policy-section">
            <p>Genbook offers powerful AI tools for creating and sharing original digital content. By generating or sharing content, you:</p>
            <ul>
              <li>Confirm you have any necessary rights or permissions for content used as prompts in the AI generation process.</li>
              <li>Grant Dimension Forge a worldwide, non-exclusive, royalty-free licence to reproduce, modify, display, and use your AI-generated content within the Platform or for promotional purposes.</li>
              <li>Acknowledge that shared content may be publicly visible to other community members who may interact, comment on, or share links to your content.</li>
            </ul>
            <p><strong className='policy-section-strong'>Note:</strong> Dimension Forge does not claim ownership over your AI-generated
content. However, for safety and compliance, Dimension Forge may review,
remove, or moderate content that violates these Terms.</p>
          </section>

          <h3 className='policy-subhead'>6. INTELLECTUAL PROPERTY RIGHTS</h3>
          <section className="policy-section">
            <p>Dimension Forge retains ownership of all intellectual property rights associated with Genbook, including AI models, technology, and branding assets. You agree not to:</p>
            <ul>
              <li>Modify, copy, or attempt to extract source code</li>
              <li>Create derivative works of the Genbook models or services.</li>
              <li>Infringe on the intellectual property rights of Dimension Forge or any third party,
              including copyrights, trademarks, or patents</li>
              <li>Distribute any part of Genbook or its features to others without permission from
              Dimension Forge.</li>
            </ul>
          </section>

          <h3 className='policy-subhead'>7. COMMUNITY GUIDELINES AND INTERACTIONS</h3>
          <section className="policy-section">
            <p>Genbook is designed to be an inclusive, positive space for networking and community engagement. When interacting within Genbook, you agree to:</p>
            <ul>
              <li>Use respectful and constructive language in comments, messages, and shared content.</li>
              <li>Avoid harassing, bullying, or making threats toward any users.</li>
              <li>Refrain from sharing private information without explicit consent.</li>
              <li>Report content or conduct that violates these Terms by contacting Genbook support
              at contactus@dimensionforge.ai.</li>
            </ul>
            <p>Dimension Forge reserves the right to moderate, limit, or remove accounts that violate
            community standards or terms.</p>
          </section>

          <h3 className='policy-subhead'>8. THIRD-PARTY SERVICES, INCLUDING ADVERTISING PARTNERS</h3>
          <section className="policy-section">
            <p>Genbook may include third-party content, advertisements, or links for added functionality. By interacting with these services, you acknowledge that:</p>
            <ul>
              <li>Dimension Forge is not responsible for any third-party content, services, or actions,
              and your engagement is at your own risk.</li>
              <li>Third-party services are governed by their respective privacy policies, terms, and
              conditions, which you agree to review and comply with.</li>
              <li>Third-party advertising may be personalized based on your activity within Genbook
and may include links to other websites. Dimension Forge does not guarantee or
endorse any third-party products or services.</li>
            </ul>
            <p><strong className='policy-section-strong'>Advertising and Analytics:</strong> Genbook may share non-identifiable information
with advertising partners to improve ad relevance. By using Genbook, you
consent to this data collection and agree to review our Privacy Policy for more
details on data sharing.</p>
          </section>

          <h3 className='policy-subhead'>9. DATA SECURITY AND USER RESPONSIBILITIES</h3>
          <section className="policy-section">
            <p>While Dimension Forge strives to protect user data and maintain security, Genbook may not be entirely secure. You are responsible for keeping your device and account secure.</p>
            <ul>
              <li>Keeping your device and account secure.</li>
              <li>Promptly notifying Dimension Forge of any unauthorised access to your account.</li>
              <li>Ensuring compliance with any device or network security measures required to
              safeguard your account.</li>
            </ul>
          </section>

          <h3 className='policy-subhead'>10. CONTENT MONITORING AND MODERATION</h3>
          <section className="policy-section">
            <p>Dimension Forge reserves the right to monitor and moderate user-generated content to ensure compliance with these Terms and applicable laws.</p>
            <ul>
              <li>Remove, limit, or modify content that violates these Terms or threatens community
              safety.</li>
              <li>Use automated tools or human moderators to monitor for prohibited content.</li>
              <li>Review user reports of inappropriate content and take action accordingly.</li>
            </ul>
          </section>

          <h3 className='policy-subhead'>11. ACCOUNT AND PASSWORD SECURITY</h3>
          <section className="policy-section">
            <p>You are responsible for the confidentiality of your Genbook login credentials. Any activity
conducted under your account is your responsibility. Notify Genbook immediately if you
suspect unauthorised access to your account</p>
          </section>

          <h3 className='policy-subhead'>12. TERMINATION OF SERVICE</h3>
          <section className="policy-section">
            <p>Dimension Forge reserves the right to suspend or terminate access to Genbook if a user is
found in violation of these Terms or engaged in behaviour deemed harmful to the community
or platform. Termination may occur with or without prior notice, at Dimension Forge's
discretion.</p>
          </section>

          <h3 className='policy-subhead'>13. WARRANTY DISCLAIMER</h3>
          <section className="policy-section">
            <p>Genbook is provided “AS IS” without warranties of any kind, express or implied. To the
            extent permitted by law, Dimension Forge disclaims any warranties related to:</p>
            <ul>
              <li>Platform availability and performance</li>
              <li>Compatibility with other software, hardware, or networks.</li>
              <li>Accuracy, reliability, or currency of any content provided by or generated within
              Genbook.</li>
            </ul>
          </section>

          <h3 className='policy-subhead'>14. LIMITATION OF LIABILITY</h3>
          <section className="policy-section">
            <p>To the fullest extent permitted by law, Dimension Forge is not liable for any indirect,
incidental, special, or consequential damages resulting from your use of Genbook, including
but not limited to data loss, business interruptions, or any third-party conduct on the platform.</p>
          </section>

          <h3 className='policy-subhead'>15. AMENDMENTS TO TERMS OF USE</h3>
          <section className="policy-section">
            <p>Dimension Forge may revise these Terms periodically to reflect changes in the Platform,
legal requirements, or industry practices. Material changes will be communicated through
Genbook or directly to registered users, with a minimum 30-day notice period. Continued
use of Genbook signifies acceptance of the updated terms.</p>
          </section>

          <h3 className='policy-subhead'>16. GOVERNING LAW AND DISPUTE RESOLUTION</h3>
          <section className="policy-section">
            <p>These Terms of Use are governed by the laws of the United Kingdom. Disputes shall be resolved in UK courts, subject to applicable jurisdiction.</p>
          </section>

          <h3 className='policy-subhead'>17. ENTIRE AGREEMENT</h3>
          <section className="policy-section">
            <p>These Terms of Use constitute the complete agreement between you and Dimension Forge
            regarding Genbook, superseding all prior agreements.</p>
          </section>

          <h3 className='policy-subhead'>18. CONTACT INFORMATION</h3>
          <section className="policy-section">
            <p>If you have questions, concerns, or suggestions, please contact Dimension Forge at:</p>
            <ul>
              <li><strong className='policy-section-strong'>Email:</strong> contactus@dimensionforge.ai</li>
              <li><strong className='policy-section-strong'>Mailing Address:</strong> 3rd Floor, 86-90 Paul St, London EC2A 4NE</li>
            </ul>
          </section>
        </Col>
      </Row>
      <Footer/>
    </Container>
  );
};

export default TermsPage;

import React, { useState,useEffect} from 'react';
import { Navbar, Nav, Container, Form, Button ,Modal,Alert} from 'react-bootstrap';
import Assets from '../components/Assets.tsx';
import Portfolio from '../components/Portfolio.tsx';
import TrendingCreatives from '../components/TrendingCreatives.tsx';
import PremiumCreatives from '../components/PremiumCreatives.tsx';
import Footer from '../components/Footer.tsx';
import { TbMenuDeep, TbSearch, TbArrowDownCircle } from 'react-icons/tb';
import { CiSquareChevRight } from "react-icons/ci";
import '../assets/styles/landing-page.css';
import logo_img from '../assets/images/GenbookStudio_logo.png'

const LandingPage: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const [profession, setProfession] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    document.title = "Genbook Studio - Monetize Your Digital Assets with AI";

    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Join Genbook Studio and unlock your creative potential. Monetize your digital assets with AI and thrive in the digital creators economy.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "Genbook, Genbook Studio, monetize, digital assets, AI, digital creators, digital creators economy";
    document.head.appendChild(metaKeywords);

    const metaAuthor = document.createElement('meta');
    metaAuthor.name = "author";
    metaAuthor.content = "Genbook Studio Team";
    document.head.appendChild(metaAuthor);

    const linkCanonical = document.createElement('link');
    linkCanonical.rel = "canonical";
    linkCanonical.href = "https://genbookstudio.com";
    document.head.appendChild(linkCanonical);

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
      document.head.removeChild(metaAuthor);
      document.head.removeChild(linkCanonical);
    };
}, []);


  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => {
    setShowModal(false);
    setFullName('');
    setEmail('');
    setLocation('');
    setProfession('');
    setSuccessMessage('');
    setErrorMessage('');
  };

  const scrollToWaitlist = () => {
    const waitlistSection = document.getElementById('waitlist-section');
    if (waitlistSection) {
      waitlistSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    const details = {
      fullName,
      email,
      location,
      profession,
    };

    try {
      const response = await fetch('https://genbookpage-75072625448e.herokuapp.com/waitlist/join-waitlist', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(details),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(data.message || 'You have successfully joined the waitlist!');
      } else {
        const data = await response.json();
        setErrorMessage(data.detail || 'Failed to join the waitlist. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="landing-page">
      {/* Navbar */}
      <Navbar expand="lg" className="py-2 landing-header-container">
        <Container fluid className="d-flex justify-content-between align-items-center">
          <Navbar.Brand href="/" className="d-flex align-items-center">
            <img src={logo_img} alt='logo' className='header-logo'/>
            <span className="landing-navbar-title">Genbook Studio</span>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
            className='header-toggle'
          >
            <TbMenuDeep className="landing-header-icon" />
          </Navbar.Toggle>
          <Nav className="ml-auto d-flex align-items-center">
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="header-text-links">
                <Nav.Link href="#community" className="header-text-link" onClick={() => setExpanded(false)}>Community</Nav.Link>
                <Nav.Link href="#pricing" className="header-text-link" onClick={() => setExpanded(false)}>Pricing</Nav.Link>
                <Nav.Link href="#about-us" className="header-text-link" onClick={() => setExpanded(false)}>About Us</Nav.Link>
                <Nav.Link href="#" className="header-text-link" onClick={scrollToWaitlist}>Early Access</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Nav>
        </Container>
      </Navbar>

      <div className="fixed-waitlist-button" onClick={scrollToWaitlist}>
      Secure your spot <TbArrowDownCircle className="waitlist-icon" style={{fontSize:"2rem"}}/>
      </div>

      <Modal show={showModal} onHide={handleModalClose} centered className='waitlist-modal'>
        <Modal.Header closeButton style={{border:"none"}}>
          <Modal.Title className="waitlist-heading">Join Our Waitlist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form.Group controlId="formFullName">
              <Form.Control
                type="text"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Control
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formLocation">
              <Form.Control
                type="text"
                placeholder="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formProfession">
              <Form.Control
                type="text"
                placeholder="Profession"
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="modal-waitlist-btn">
              Join Waitlist
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Main Content */}
      <div>
        <Assets />
        <Portfolio />
        <div className='landing-inspiration'>
          <h1 className='landing-inspiration-text'>We help people find thier creative identity.<br /><br />We help you design your vision</h1>
          <Nav.Link href="#" className='studio-link-container' onClick={scrollToWaitlist}>
            <h2 id="studio-link" className="landing-nav-heading .btn.grad">Go to genbook studio</h2>
          </Nav.Link>
        </div>
        <TrendingCreatives />
        <PremiumCreatives />

        {/* Waitlist Section */}
        <section id="waitlist-section">
          <div className='join-mobile-view'>
          <h2 className="waitlist-heading">Join the waitlist Today</h2>
          <p className='waitlist-subheading'>Be among the first to unlock exclusive early access benefits and a sneak peek into our exciting new features! <br/> Don't miss out—secure your spot now!.</p>
          <Button variant="primary" type="submit" className="join-waitlist-btn" onClick={handleModalOpen}>
            Get Started <CiSquareChevRight className='waitlist-icon'/>
          </Button>
          </div>  
        </section>

        {/* Footer */}
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;

import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import '../assets/styles/trending-creatives.css';

const trendingCreativesData = [
  {
    id: 1,
    creatorName: "John Doe",
    projectName: "Project Alpha",
    squadName: "Creative Squad",
    profileImage: require('../assets/images/imgs-trending-creatives/1.webp'),
  },
  {
    id: 2,
    creatorName: "Jane Smith",
    projectName: "Project Beta",
    squadName: "Design Squad",
    profileImage: require('../assets/images/imgs-trending-creatives/2.webp'),
  },
  {
    id: 3,
    creatorName: "Alice Johnson",
    projectName: "Project Gamma",
    squadName: "Image Craft",
    profileImage: require('../assets/images/imgs-trending-creatives/3.webp'),
  },
  {
    id: 4,
    creatorName: "Michael Brown",
    projectName: "Project Delta",
    squadName: "Poster Squad",
    profileImage: require('../assets/images/imgs-trending-creatives/4.jpg'),
  },
  {
    id: 5,
    creatorName: "Emily Davis",
    projectName: "Project Epsilon",
    squadName: "Newsletter Crew",
    profileImage: require('../assets/images/imgs-trending-creatives/5.png'),
  },
];

const TrendingCreatives: React.FC = () => {
  return (
    <div className="creations-section">
      <h2>Explore Trending Creatives</h2>
      <div className="landing-scrollable-container">
      <Row className="creative-row">
        {trendingCreativesData.map((item) => (
          <div key={item.id} className="creative-box">
            <div className="creative-content" style={{backgroundImage:`url(${item.profileImage})`}}></div>
            <div className="creative-profile">
              <div className="creative-details">
                <div className="creative-footer">{item.creatorName}</div>
                <div className="creative-sub-footer">{item.squadName}</div>
              </div>
            </div>
            <div className="project-name">{item.projectName}</div>
          </div>
        ))}
      </Row>
      </div>
    </div>
  );
};

export default TrendingCreatives;
